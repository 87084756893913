window.hammerAnimation = new URL('./img/hammerAnimation.riv', import.meta.url);
window.handAnimation = new URL('./img/handAnimation.riv', import.meta.url);
window.heartAnimation = new URL('./img/heartAnimation.riv', import.meta.url);

const hammerAnimation = new rive.Rive({
	src: window.hammerAnimation,
	canvas: document.getElementById('reactionHammer'),
	autoplay: true,
	stateMachines: 'State Machine 1',
						
	onLoad: (_) => {
		const inputs = hammerAnimation.stateMachineInputs('State Machine 1');
		const touchTrigger = inputs.find(i => i.name === 'touch');
		const clickTrigger = inputs.find(i => i.name === 'hit');
		window.addEventListener('mousedown',function(e) {
			clickTrigger.fire();
		});
	},
});
const handAnimation = new rive.Rive({
	src: window.handAnimation,
	canvas: document.getElementById('reactionHand'),
	autoplay: true,
	stateMachines: 'State Machine 1',
						
	onLoad: (_) => {
		const inputs = handAnimation.stateMachineInputs('State Machine 1');
		const chooseTrigger = inputs.find(i => i.name === 'choose');
		const clickTrigger = inputs.find(i => i.name === 'high five');
		window.addEventListener('mousedown',function(e) {
			clickTrigger.fire();
		});
	},
});
const heartAnimation = new rive.Rive({
	src: window.heartAnimation,
	canvas: document.getElementById('reactionHeart'),
	autoplay: true,
	stateMachines: 'State Machine 1',
						
	onLoad: (_) => {
		const inputs = heartAnimation.stateMachineInputs('State Machine 1');
		const chooseTrigger = inputs.find(i => i.name === 'choose');
		const clickTrigger = inputs.find(i => i.name === 'click');
		window.addEventListener('mousedown',function(e) {
			clickTrigger.fire();
		});
	},
});